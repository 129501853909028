import styled from "styled-components"

const Title = styled.h1`
  font-size: clamp(4em, calc(2em + 10vw), 10em);
  line-height: 1em;
  font-weight: normal;
  text-align: center;
  margin: 0.5em 0;
  color: hotpink;
  @media (prefers-color-scheme: dark) {
    color: lightseagreen;
  }
`

export default Title
