import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Title from "../components/Title"
import { Container } from "../components/Container"
import { Link } from "gatsby"
import styled from "styled-components"

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 1em;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "Person",
          name: "Jack Nevitt",
          hasOccupation: {
            "@type": "Occupation",
            name: "Front End Web Engineer",
          },
          birthPlace: {
            "@type": "Place",
            name: "London",
          },
        })}
      </script>
    </Helmet>
    <Container>
      <Title>
        Jack
        <br />
        Nevitt
      </Title>
      <div style={{ textAlign: "center" }}>
        <p>Nice to meet you.</p>
        <p>I'm a Front End Web Engineer from London.</p>
        <p>
          Check out my <Link to="/blog">blog page</Link>?
        </p>
        <nav>
          <List>
            <li>
              <a
                href="https://twitter.com/jacknevdev"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter" aria-label="Twitter"></i>{" "}
                @jacknevdev
              </a>
            </li>
            <li>
              <a
                href="https://github.com/jacknevitt"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-github" aria-label="GitHub"></i>{" "}
                @jacknevitt
              </a>
            </li>
            <li>
              <a
                href="https://codepen.io/Jacknev"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-codepen" aria-label="Codepen" /> @Jacknev
              </a>
            </li>
          </List>
        </nav>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
